import {
  Configuration,
  IssuesApi,
} from '~/services/generated/client/public'

export function useApi() {
  const { public: { brewApiUrl: basePath } } = useRuntimeConfig()

  const apiConfiguration = computed(() => new Configuration({
    basePath,
  }))

  const issuesApi = computed(() => new IssuesApi(apiConfiguration.value))

  return {
    issuesApi: readonly(issuesApi),
  }
}
