import { captureException } from '@sentry/vue'
import type { InvestResponse, Issue, IssueList } from '~/services/generated/client/public'

export function useIssues() {
  // const { notify } = useToast()
  const { issuesApi } = useApi()

  // loading states
  const isLoading = ref(false)
  const investmentLoading = ref(false)

  // data
  const issues = useState<IssueList[]>('issues', () => [])
  const issue = useState<Issue>('issue')
  const investmentEstimate = shallowRef<InvestResponse>()

  // methods
  async function fetchIssues() {
    isLoading.value = true
    try {
      issues.value = await issuesApi.value.getIssues()
    }
    catch (err) {
      captureException(err)
      throw err
    }
    finally {
      isLoading.value = false
    }
  }

  async function fetchIssue(id: number) {
    isLoading.value = true
    try {
      issue.value = await issuesApi.value.getIssue({ id })
    }
    catch (err) {
      captureException(err)
    }
    finally {
      isLoading.value = false
    }
  }

  async function fetchIssueInvestmentEstimate(amount: number, issueId: number) {
    isLoading.value = true

    try {
      const response = await issuesApi.value.getIssueInvestmentEstimate({ amount, id: issueId })

      investmentEstimate.value = response
    }
    catch (err) {
      captureException(err)
    }
    finally {
      isLoading.value = false
    }
  }

  return {
    fetchIssues,
    fetchIssue,

    issues,
    issue,
    investmentEstimate,
    isLoading,
    investmentLoading,
    fetchIssueInvestmentEstimate,

  }
}
