/* tslint:disable */
/* eslint-disable */
/**
 * Brew API
 * Microservice backend for brew management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import dayjs from 'dayjs';
import type {
  InvestResponse,
  Issue,
  IssueList,
} from '../models/index';
import {
    InvestResponseFromJSON,
    InvestResponseToJSON,
    IssueFromJSON,
    IssueToJSON,
    IssueListFromJSON,
    IssueListToJSON,
} from '../models/index';

export interface GetIssueRequest {
    id: number;
}

export interface GetIssueInvestmentEstimateRequest {
    amount: number;
    id: number;
}

/**
 * 
 */
export class IssuesApi extends runtime.BaseAPI {

    /**
     * This is the magic.  Overrides `.as_view()` so that it takes an `actions` keyword that performs the binding of HTTP methods to actions on the Resource.  For example, to create a concrete view binding the \'GET\' and \'POST\' methods to the \'list\' and \'create\' actions...  view = MyViewSet.as_view({\'get\': \'list\', \'post\': \'create\'})
     */
    async getIssueRaw(requestParameters: GetIssueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Issue>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getIssue().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/public/v1/issues/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueFromJSON(jsonValue));
    }

    /**
     * This is the magic.  Overrides `.as_view()` so that it takes an `actions` keyword that performs the binding of HTTP methods to actions on the Resource.  For example, to create a concrete view binding the \'GET\' and \'POST\' methods to the \'list\' and \'create\' actions...  view = MyViewSet.as_view({\'get\': \'list\', \'post\': \'create\'})
     */
    async getIssue(requestParameters: GetIssueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Issue> {
        const response = await this.getIssueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getIssueInvestmentEstimateRaw(requestParameters: GetIssueInvestmentEstimateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvestResponse>> {
        if (requestParameters['amount'] == null) {
            throw new runtime.RequiredError(
                'amount',
                'Required parameter "amount" was null or undefined when calling getIssueInvestmentEstimate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getIssueInvestmentEstimate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['amount'] != null) {
            queryParameters['amount'] = requestParameters['amount'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/public/v1/issues/{id}/invest/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvestResponseFromJSON(jsonValue));
    }

    /**
     */
    async getIssueInvestmentEstimate(requestParameters: GetIssueInvestmentEstimateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvestResponse> {
        const response = await this.getIssueInvestmentEstimateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This is the magic.  Overrides `.as_view()` so that it takes an `actions` keyword that performs the binding of HTTP methods to actions on the Resource.  For example, to create a concrete view binding the \'GET\' and \'POST\' methods to the \'list\' and \'create\' actions...  view = MyViewSet.as_view({\'get\': \'list\', \'post\': \'create\'})
     */
    async getIssuesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IssueList>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/public/v1/issues/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IssueListFromJSON));
    }

    /**
     * This is the magic.  Overrides `.as_view()` so that it takes an `actions` keyword that performs the binding of HTTP methods to actions on the Resource.  For example, to create a concrete view binding the \'GET\' and \'POST\' methods to the \'list\' and \'create\' actions...  view = MyViewSet.as_view({\'get\': \'list\', \'post\': \'create\'})
     */
    async getIssues(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IssueList>> {
        const response = await this.getIssuesRaw(initOverrides);
        return await response.value();
    }

}
